define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"bp-cross-sell cf\">\n	";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.header), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.header), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n	";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.content), options) : helperMissing.call(depth0, "rawHTML", (depth0 && depth0.content), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>";
  return buffer;
  }); });